import { useRouter } from "next/router";
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";

interface ParamsProps {
  params: string;
}

interface UserProviderProps {
  children: ReactNode;
}

const ParamsContext = createContext<ParamsProps>({} as ParamsProps);

export const ParamsProvider = ({ children }: UserProviderProps) => {
  const [params, setParams] = useState("");
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === "/404") {
      setParams("");
    } else {
      const params = router.asPath.replace(router.pathname, "").replaceAll("?", "");
      setParams(`${params ? `?${params}` : ""}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  return (
    <ParamsContext.Provider value={{ params }}>
      {children}
    </ParamsContext.Provider>
  );
};

export const useParams = () => useContext(ParamsContext);
