import { getCookie, setCookie } from "./useCookies";
import { ParsedUrlQuery } from "querystring";

const firstInteractionFieldList: Array<string> = [
  "utm_source_first",
  "utm_medium_first",
  "utm_campaign_first",
  "utm_content_first",
  "utm_term_first",
  "has_src_first",
  "has_cam_first",
  "has_grp_first",
  "has_ad_first",
];

export const useFirstInteractionFields = () => {
  return {
    setFirstInteractionInCookies: (params: ParsedUrlQuery) => {
      firstInteractionFieldList.forEach((field: string) => {
        const cookieValue = getCookie(field);
        const paramValue = params[field.replace("_first", "")];

        if (cookieValue === undefined && paramValue !== undefined)
          setCookie({ name: field, value: paramValue as string });
      });
    },
  };
};
