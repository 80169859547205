import styles from "./styles.module.scss";

export default function Footer() {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <img
          className={styles.logo}
          src="/images/footer-logo.svg"
          alt="Kenzie Academy Logo"
          width="64"
          height="64"
        />

        <div className={styles.content}>
          <div>
            <h3 className={styles.title}>Kenzie na rede</h3>

            <ul className={styles.socialList}>
              <li>
                <a
                  href="https://www.facebook.com/kenzieacademybr/?utm_campaign=home&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/kenzieacademybr/?utm_campaign=home&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC6rcCbDzhVoIm1V7WnwPDIQ?sub_confirmation=1?utm_campaign=home&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  YouTube
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/school/kenzie-academy-brasil/?utm_campaign=home&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@kenzieacademy_br?utm_campaign=home&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  TikTok
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/kenzieacademybr?utm_campaign=home&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className={styles.title}>Contato</h3>

            <ul className={styles.contactList}>
              <li>
                R. Barão do Cerro Azul, 952 - Terreo 01 - Centro, São José dos Pinhais - PR, 83005-430
              </li>
              <li>Avenida Efigênio Salles, 1299 - Aleixo, Manaus/AM</li>
              <li>CNPJ 35.224.825/0001-74</li>
              <li>
                <a href="mailto:contato@kenzie.com.br">
                  contato@kenzie.com.br
                </a>
              </li>
              <li>
                <a
                  href="https://kenzie.gupy.io/"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.career}
                >
                  Trabalhe Conosco
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.footnote}>
        <p>&copy; 2022 Kenzie Academy. All rights reserved.</p>
      </div>
    </footer>
  );
}
