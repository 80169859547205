// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  ignoreErrors: [
    // Ignore Google flakiness
    "TypeError: ga.getAll is not a function",
    "ReferenceError: ga is not defined",
    "ga is not defined",
    /\/(gtm|ga|analytics|events)\.js/i,
    /TypeError.*/,
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
    "SyntaxError",
    // Ignore errors likely caused by browsers/extensions/webviews
    /^No error$/,
    /__show__deepen/,
    /_avast_submit/,
    /Access is denied/,
    /anonymous function: captureException/,
    /Blocked a frame with origin/,
    /can't redefine non-configurable property "userAgent"/,
    /change_ua/,
    /console is not defined/,
    /cordova/,
    /DataCloneError/,
    /Error: AccessDeny/,
    /event is not defined/,
    /feedConf/,
    /ibFindAllVideos/,
    /myGloFrameList/,
    /SecurityError/,
    /MyIPhoneApp/,
    /snapchat.com/,
    /vid_mate_check is not defined/,
    /win\.document\.body/,
    /window\._sharedData\.entry_data/,
    /window\.regainData/,
    /ztePageScrollModule/,
    /raygunFactory/,
    /__inspld/,
    /SyntaxHighlighter/,
    /__LOADABLE_LOADED_CHUNKS__/,
    /ZipifyPages/,
    /Raygun/,
    /search_api_base_uri/,
    /prestashop/,
    /__insp/,
    /AddSearchUI/,
    /$ae/,
    /KOHA/,
    /Catch/,
    /Rise/,
    /InteractPromotionObject/,
    /personaclick_callback/,
    /personaclick/,
    /ihfJquery/,
    /SMSBumpForm/,
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
    // Avast extension error
    "_avast_submit",
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
