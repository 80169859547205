import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

export default function Cookies() {
  const [active, setActive] = useState(false);

  const getKey = () => {
    if (typeof window !== "undefined") {
      if (navigator.cookieEnabled) {
        try {
          const key = localStorage.getItem("cookies");
          if (!key) {
            setActive(true);
          }
        } catch (e) {
          setActive(true);
        }
      } else {
        setActive(true);
      }
    }
  };

  useEffect(() => {
    getKey();
  }, []);

  const handleAccept = () => {
    if (typeof window !== "undefined") {
      if (navigator.cookieEnabled) {
        try {
          localStorage.setItem("cookies", "accept");
          setActive(false);
        } catch (e) {
          setActive(false);
        }
      } else {
        setActive(false);
      }
    }
  };

  return (
    <>
      {active && (
        <div className={styles.container}>
          <div>
            <p>
              Usamos cookies e outras tecnologias semelhantes para melhorar sua
              experiência. Ao navegar neste site, você concorda com nossos{" "}
              <a
                href="https://inscricao.kenzie.com.br/termos-condicoes"
                rel="noreferrer"
                target="_blank"
              >
                Termos
              </a>
              .
            </p>
          </div>

          <button onClick={handleAccept}>Entendi</button>
        </div>
      )}
    </>
  );
}
