import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { ParamsProvider } from "../hooks/Params";
import { useFirstInteractionFields } from "../hooks/useFirstInteractionFields";
import type { AppProps } from "next/app";
import Footer from "../components/Footer";
import Cookies from "../components/Cookies";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "../styles/global.scss";
import "../styles/youtube.scss";

const isRouteWithoutLayout = (
  path: string,
  routesWithoutLayout = [/certificados/]
) => routesWithoutLayout.some((route) => route.test(path));

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const params = router.query;
  const { setFirstInteractionInCookies } = useFirstInteractionFields();

  if (typeof document !== "undefined") setFirstInteractionInCookies(params);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-NB5L2PM" });
  }, []);

  return (
    <ParamsProvider>
      <Component {...pageProps} />
      {!isRouteWithoutLayout(router.asPath) && (
        <>
          <Footer />
          <Cookies />
        </>
      )}
    </ParamsProvider>
  );
}

export default MyApp;
